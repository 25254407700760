import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, mergeMap, startWith, switchMap, withLatestFrom } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { WorkbenchService } from '../services/workbench.service';

import * as fromAction from './../actions/analysis-results.action';
import * as fromPopupActions from './../../../../../variant-page/modules/interpretation/store/actions/interpretation-header.action';
import { Action, select, Store } from '@ngrx/store';
import { VariantsState } from '../../../variants/store';
import { WorkbenchModuleState } from '../reducers';
import {
  getAnalysisResultAsEntities,
  getPatientInfoResponse,
  getToggledPanels,
  getToggledPhenotypes,
  getVariantInterpretations,
  getVarIdsSortedByUiGroups,
} from '../selectors';
import {
  AnalysisDetails,
  AnalysisType,
  AppModuleState,
  getAnalysisDetails,
  getAnalysisId,
  getParams,
  getPath,
  OpenSnackbar,
} from '../../../../../../store';
import * as fromVariantPage from '../../../../../variant-page/modules/interpretation/store';
import {
  SAVE_VARIANT_INTERPRETATION_SUCCESS,
  SaveVariantInterpretationSuccess,
} from '../../../../../variant-page/modules/interpretation/store';
import { JsonService } from '../../../shared/services/json.service';
import { NOT_RELEVANT_SIGNIFICANCE_GROUP, UpdateBinOrderingRequest } from '../models';
import {
  AnalysisResult,
  SnpResult,
  SvResult,
  VariantAction,
  VariantActionValue,
  VariantInterpretation,
  VariantInterpretationType,
} from '../../../analysis-variant/models';
import {
  TAKE_BULK_VARIANT_ACTION_SUCCESS,
  TakeBulkVariantAction,
  TakeBulkVariantActionSuccess,
  TOGGLE_PANEL,
  TOGGLE_PHENOTYPE,
} from '../actions';
import { CREATE_TOPIC_SUCCESS } from '../../../../../variant-page/modules/discussion/store';
import { editVariantSubmitSuccess } from '../../../../../variant-page/modules/edit-variant-mini-app/store';
import { RELOAD_ANALYSIS_VARIANTS } from './..';
import { Params } from '@angular/router';
import { COMPOUND_ID_SEPARATOR } from '../../utils/analysis-result.utils';
import { SnackbarMode } from '../../../../../../store/models/snackbar.model';
import { FiltersService } from '../../../filters/store';
import { PredefinedFilter } from '../../../filters/store/models/predefined-filter.model';

@Injectable()
export class AnalysisResultsEffects {
  constructor(
    private dataService: WorkbenchService,
    private interpretationService: JsonService,
    private actions$: Actions,
    private store$: Store<WorkbenchModuleState>,
    private variantsStore$: Store<VariantsState>,
    private rootStore$: Store<AppModuleState>,
    private filterService: FiltersService,
  ) {}

  loadAnalysisResults$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.LOAD_ANALYSIS_RESULTS),
      withLatestFrom(
        this.rootStore$.pipe(select(getParams)),
        this.filterService.getActivePredefinedFilters$().pipe(startWith([])),
      ),
      switchMap(
        ([action, { variantType }, selectedQuickFilters]: [
          fromAction.LoadAnalysisResults,
          Params,
          (number | string)[],
        ]) => {
          if (action.source === 'wb') {
            return this.dataService
              .getAnalysisResults(
                action.analysisId,
                action.phenotypes,
                action.cancer_type,
                action.panels ? action.panels?.map((panel) => `${panel.id}`) : [],
              )
              .pipe(
                switchMap((data: AnalysisResult[]) => [
                  new fromAction.LoadAnalysisResultsSuccess(action.analysisId, data),
                ]),
                catchError((error) => of(new fromAction.LoadAnalysisResultsFail(action.analysisId, error))),
              );
          } else if (action.source === 'variant-list') {
            const params: Params = action.queryParams.selected_label_id?.length
              ? { ...action.queryParams }
              : {
                  selected_quick_filter_id: selectedQuickFilters,
                  ...action.queryParams,
                };
            return this.interpretationService
              .getAnalysisVariants(params, action.analysisId, action.analysisType, variantType)
              .pipe(
                switchMap((results) => {
                  if (!results) {
                    return [new fromAction.LoadAnalysisResultsFail(action.analysisId, 'invalid response from BE')];
                  } else {
                    return [
                      new fromAction.LoadAnalysisResultsSuccess(action.analysisId, results),
                      new fromAction.LoadAnalysisVariantsDetails(action.queryParams),
                    ];
                  }
                }),
                catchError((error) => of(new fromAction.LoadAnalysisResultsFail(action.analysisId, error))),
              );
          } else {
            return of(new fromAction.LoadAnalysisResultsFail(action.analysisId, `unknown source ${action.source}`));
          }
        },
      ),
    ),
  );

  reloadResults$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SAVE_VARIANT_INTERPRETATION_SUCCESS,
        TOGGLE_PHENOTYPE,
        TOGGLE_PANEL,
        RELOAD_ANALYSIS_VARIANTS,
        CREATE_TOPIC_SUCCESS,
        editVariantSubmitSuccess.type,
      ),
      withLatestFrom(this.rootStore$.select(getPath)),
      filter(
        ([action, path]: [Action, string]) =>
          path === 'workbench' &&
          !(
            action.type === SAVE_VARIANT_INTERPRETATION_SUCCESS &&
            (action as SaveVariantInterpretationSuccess).avoidReloadResults
          ),
      ),
      withLatestFrom(
        this.rootStore$.pipe(select(getAnalysisId)),
        this.rootStore$.pipe(select(getAnalysisDetails)),
        this.store$.pipe(select(getPatientInfoResponse)),
        this.store$.pipe(select(getToggledPhenotypes)),
        this.store$.pipe(select(getToggledPanels)),
      ),
      switchMap(([, analysisId, analysisDetails, caseContext, toggledPhenotypes, toggledPanels]) => [
        new fromAction.LoadAnalysisResults(
          'wb',
          analysisId,
          analysisDetails.analysis_type,
          caseContext?.phenotypes ? caseContext.phenotypes.filter((pheno) => !toggledPhenotypes?.includes(pheno)) : [],
          analysisDetails.disease,
          caseContext?.kb_panels
            ? caseContext.kb_panels.filter(
                (panel) =>
                  !toggledPanels?.some(
                    (toggledPanel) => toggledPanel.id === panel.id && toggledPanel.location === panel.location,
                  ),
              )
            : [],
        ),
      ]),
    ),
  );

  getClinicalSignificanceGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.GET_CLINICAL_SIGNIFICANCE_GROUPS),
      withLatestFrom(this.rootStore$.pipe(select(getAnalysisDetails))),
      switchMap(([, analysis]: [fromAction.GetClinicalSignificanceGroups, AnalysisDetails]) =>
        this.dataService.getClinicalSignificanceGroups(analysis).pipe(
          switchMap((result) => [new fromAction.GetClinicalSignificanceGroupsSuccess(analysis.assay?.uuid, result)]),
          catchError((error) => of(new fromAction.GetClinicalSignificanceGroupsFail(analysis.assay?.uuid, error))),
        ),
      ),
    ),
  );

  getClinicalSignificanceReasoningOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.GET_CLINICAL_SIGNIFICANCE_REASONING_OPTIONS),
      withLatestFrom(this.rootStore$.pipe(select(getAnalysisDetails))),
      switchMap(([, analysis]: [fromAction.GetClinicalSignificanceReasoningOptions, AnalysisDetails]) =>
        this.dataService.getClinicalSignificanceReasoningOptions(analysis.assay?.uuid).pipe(
          switchMap((result) => [
            new fromAction.GetClinicalSignificanceReasoningOptionsSuccess(analysis.assay?.uuid, result),
          ]),
          catchError((error) =>
            of(new fromAction.GetClinicalSignificanceReasoningOptionsFail(analysis.assay?.uuid, error)),
          ),
        ),
      ),
    ),
  );

  setVariantClinicalSignificance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.SET_VARIANT_CLINICAL_SIGNIFICANCE),
      withLatestFrom(
        this.rootStore$.pipe(select(getAnalysisDetails)),
        this.store$.pipe(select(getVariantInterpretations)),
      ),
      mergeMap(
        ([action, analysisDetails, interpretations]: [
          fromAction.SetVariantClinicalSignificance,
          AnalysisDetails,
          { [varId: string]: VariantInterpretation },
        ]) => {
          const setSignificance = this.dataService
            .setVariantClinicalSignificance(
              action.variantType,
              action.varId,
              action.analysisId,
              action.significance,
              action.reasons,
              action.details,
            )
            .pipe(
              switchMap(() => [
                new fromAction.SetVariantClinicalSignificanceSuccess(
                  action.analysisType,
                  action.variantType,
                  action.varId,
                  action.significance,
                  action.reasons,
                  action.details,
                ),
              ]),
              catchError((error) => of(new fromAction.SetVariantClinicalSignificanceFail(error, action.significance))),
            );

          const irrelevant = interpretations[action.varId]?.irrelevant;

          if (action.significance === NOT_RELEVANT_SIGNIFICANCE_GROUP || !irrelevant) {
            return setSignificance;
          } else {
            return this.interpretationService
              .updateVariantInterpretation(analysisDetails.id, action.varId, { irrelevant: false }, action.variantType)
              .pipe(mergeMap(() => setSignificance));
          }
        },
      ),
    ),
  );

  clearVariantClinicalSignificance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.CLEAR_VARIANT_CLINICAL_SIGNIFICANCE),
      mergeMap((action: fromAction.ClearVariantClinicalSignificance) =>
        this.dataService.clearVariantClinicalSignificance(action.variantType, action.varId, action.analysisId).pipe(
          mergeMap(() => [
            new fromAction.ClearVariantClinicalSignificanceSuccess(
              action.analysisType,
              action.variantType,
              action.varId,
              action.significance,
            ),
          ]),
          catchError((error) => of(new fromAction.ClearVariantClinicalSignificanceFail(action.varId, error))),
        ),
      ),
    ),
  );

  updateClinicalSignificanceOrdering$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.UPDATE_CLINICAL_SIGNIFICANCE_ORDERING),
      switchMap((action: fromAction.UpdateClinicalSignificanceOrdering) =>
        this.dataService.updateClinicalSignificanceOrdering(action.analysisId, action.payload).pipe(
          map(() => new fromAction.UpdateClinicalSignificanceOrderingSuccess(action.payload, action.analysisId)),
          catchError((err) =>
            of(new fromAction.UpdateClinicalSignificanceOrderingFail(err, action.payload, action.analysisId)),
          ),
        ),
      ),
    ),
  );

  updateClinicalSignificanceFail$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.UPDATE_CLINICAL_SIGNIFICANCE_ORDERING_FAIL),
      switchMap(() => of(new OpenSnackbar('Failed to reorder variants', SnackbarMode.Error))),
    ),
  );

  loadAnalysisVariantsDetails$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.LOAD_ANALYSIS_VARIANTS_DETAILS),
      withLatestFrom(this.rootStore$.pipe(select(getAnalysisId))),
      switchMap(([action, analysisId]: [fromAction.LoadAnalysisVariantsDetails, number]) => {
        return this.interpretationService.getAnalysisVariantsDetails(action.queryParams, analysisId).pipe(
          map((results) => new fromAction.LoadAnalysisVariantsDetailsSuccess(results)),
          catchError((error) => of(new fromAction.LoadAnalysisVariantsDetailsFail(error))),
        );
      }),
    ),
  );

  loadAnalysisVariantsCount$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.LOAD_ANALYSIS_VARIANTS_COUNT),
      withLatestFrom(
        this.rootStore$.pipe(select(getAnalysisId)),
        this.rootStore$.pipe(select(getParams)),
        this.filterService.getActivePredefinedFilters$().pipe(startWith([])),
      ),
      switchMap(
        ([action, analysisId, { variantType }, selectedQuickFilters]: [
          fromAction.LoadAnalysisVariantsCount,
          number,
          any,
          (number | string)[],
        ]) => {
          const params: Params = action.queryParams.selected_label_id?.length
            ? { ...action.queryParams }
            : {
                selected_quick_filter_id: selectedQuickFilters,
                ...action.queryParams,
              };
          return this.interpretationService.getAnalysisVariantsCount(params, analysisId, variantType).pipe(
            map((results) => new fromAction.LoadAnalysisVariantsCountSuccess(results)),
            catchError((error) => of(new fromAction.LoadAnalysisVariantsCountFail(error))),
          );
        },
      ),
    ),
  );

  reloadVariantsAfterInterpretationSave$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromVariantPage.SAVE_VARIANT_INTERPRETATION_SUCCESS, CREATE_TOPIC_SUCCESS, editVariantSubmitSuccess.type),
      withLatestFrom(this.rootStore$.select(getPath)),
      filter(
        ([action, path]: [Action, string]) =>
          path !== 'workbench' &&
          !(
            action.type === SAVE_VARIANT_INTERPRETATION_SUCCESS &&
            (action as SaveVariantInterpretationSuccess).avoidReloadResults
          ),
      ),
      map(() => new fromAction.ReloadAnalysisVariants()),
    ),
  );

  reorderVariantsAfterDropBin$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(TAKE_BULK_VARIANT_ACTION_SUCCESS),
      filter((action: TakeBulkVariantActionSuccess) =>
        [
          VariantAction.clinicalSignificance,
          VariantInterpretationType.irrelevant,
          VariantInterpretationType.in_report,
        ].includes(action.actionType),
      ),
      withLatestFrom(
        this.store$.pipe(select(getVarIdsSortedByUiGroups)),
        this.rootStore$.pipe(select(getAnalysisDetails)),
        this.rootStore$.select(getPath)
      ),
      filter(([, , , path]) => path === 'workbench'),
      filter(([, , analysis]) => analysis.analysis_type !== AnalysisType.tumor),
      map(
        ([action, varIdsByGroup]: [
          TakeBulkVariantActionSuccess,
          { group?: string; var_ids: string[] }[],
          AnalysisDetails,
          string
        ]) => {
          const significance =
            action.actionType === VariantInterpretationType.irrelevant
              ? action.value
                ? 'Not Relevant'
                : undefined
              : action.clinicalSignificance;
          const toGroup = varIdsByGroup.find((g) => g.group === significance);
          const toGroupVariants = [...toGroup.var_ids];
          toGroupVariants.splice(action.clinicalSignificanceDropIndex || 0, 0, ...action.variantIds);

          const fromGroup = varIdsByGroup.find((g) => g.var_ids.includes(action.variantIds[0]));
          const fromGroupVariants = fromGroup.var_ids.filter((v) => !action.variantIds.includes(v));

          if (!fromGroup.group && !toGroup.group) {
            return;
          }

          const payload: UpdateBinOrderingRequest[] = [
            {
              bin_name: toGroup.group,
              workbench_units_in_order: toGroupVariants.map((v) =>
                v.includes(COMPOUND_ID_SEPARATOR) ? { compound_ids: v.split(COMPOUND_ID_SEPARATOR) } : { var_id: v },
              ),
            },
            {
              bin_name: fromGroup.group,
              workbench_units_in_order: fromGroupVariants.map((v) =>
                v.includes(COMPOUND_ID_SEPARATOR) ? { compound_ids: v.split(COMPOUND_ID_SEPARATOR) } : { var_id: v },
              ),
            },
          ];
          return new fromAction.UpdateClinicalSignificanceOrdering(payload, action.analysisId);
        },
      ),
    ),
  );

  fromPopupHeaderButtons$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromPopupActions.INCLUDE_IN_REPORT,
        fromPopupActions.INCLUDE_IN_WORKBENCH,
        fromPopupActions.MARK_IRRELEVANT,
      ),
      withLatestFrom(this.store$.pipe(select(getAnalysisResultAsEntities))),
      map(([action, analysisResults]: [fromPopupActions.IncludeInReport, { [key: string]: AnalysisResult }]) => {
        const btn: VariantInterpretationType =
          action.type === fromPopupActions.INCLUDE_IN_REPORT
            ? VariantActionValue.in_report
            : action.type === fromPopupActions.INCLUDE_IN_WORKBENCH
              ? VariantActionValue.in_workbench
              : VariantActionValue.irrelevant;

        return new TakeBulkVariantAction(
          action.variantId.analysisId,
          action.variantId.analysisType,
          btn,
          action.value,
          [analysisResults[action.variantId.hashId]],
        );
      }),
    ),
  );
}
