import { SelectOption } from '../../../../../../shared/models';
import { AnalysisType } from '../../../../../../store';
import { VariantType } from '../../../analysis-variant/models';

export interface ValuesToSubValues {
  value: string;
  display_value: string;
  sub_values?: ValuesToSubValues[];
  is_default: boolean;
}

export interface EnumFilter {
  values_to_sub_values: ValuesToSubValues[];
  is_single_selection: boolean;
}

export interface RangeFilter {
  min_value: number;
  max_value: number;
  predefined_ranges: {
    name: string;
    start: number;
    end?: number;
  }[];
  is_int_values: boolean;
  is_percentages: boolean;
  with_na: boolean;
}

export interface Filter {
  filter_name: string;
  filter_display_name: string;
  range_filter?: RangeFilter;
  enum_filter?: EnumFilter;
  compound_snp_snp_filter?: EnumFilter;
}

export interface GroupFilter {
  group_name: string;
  group_display_name: string;
  filters: Filter[];
}

export interface CompoundFilter {
  filter_name: string;
  filter_display_name: string;
  filters: Filter[];
}

export interface FilterTreeFilter {
  single_filter?: Filter;
  group_filter?: GroupFilter;
  compound_filter?: CompoundFilter;
}

export interface FilterTreeOptions {
  filters: FilterTreeFilter[];
}

export const filterNamesToDisplayNames = (filterTreeOptions: FilterTreeOptions): Record<string, string> => {
  return filterTreeOptions.filters.reduce(
    (filtersAcc, filter) => ({
      ...filtersAcc,
      [filter.single_filter?.filter_name || filter.group_filter?.group_name || filter.compound_filter?.filter_name]:
        filter.single_filter?.filter_display_name ||
        filter.single_filter?.filter_name ||
        filter.group_filter?.group_display_name ||
        filter.group_filter?.group_name ||
        filter.compound_filter?.filter_display_name ||
        filter.compound_filter?.filter_name,
      ...filter.single_filter?.enum_filter?.values_to_sub_values.reduce(
        (valuesAcc, value) => ({
          ...valuesAcc,
          [value.value]: value.display_value || value.value,
          ...value.sub_values?.reduce(
            (subValuesAcc, subValue) => ({
              ...subValuesAcc,
              [subValue.value]: subValue.display_value || subValue.value,
            }),
            {},
          ),
        }),
        {},
      ),
    }),
    {} as Record<string, string>,
  );
};

export const filterNamesToIsPercent = (filterTreeOptions: FilterTreeOptions): Record<string, boolean> => {
  return filterTreeOptions.filters.reduce(
    (filtersAcc, filter) => ({
      ...filtersAcc,
      [filter.single_filter?.filter_name]: filter.single_filter?.range_filter?.is_percentages,
      ...filter.group_filter?.filters?.reduce(
        (valuesAcc, value) => ({
          ...valuesAcc,
          [value.filter_name]: value.range_filter?.is_percentages,
        }),
        {},
      ),
      ...filter.compound_filter?.filters?.reduce(
        (valuesAcc, value) => ({
          ...valuesAcc,
          [value.filter_name]: value.range_filter?.is_percentages,
        }),
        {},
      ),
    }),
    {} as Record<string, boolean>,
  );
};

export interface FilterTreeInfo {
  id: string;
  name: string;
  analysis_type: AnalysisType;
  variant_type: VariantType;
  creator: string;
  description: string;
  time_millis: number;
  assays: string[];
  is_delete_allowed: boolean;
  inSave?: boolean;
  use_phenotypes_as_filter: boolean;
}

export interface ContainingTree {
  tree_id: string;
  tree_name: string;
}

export interface FilterTreeLabel {
  label_id: string;
  label_name: string;
  description: string;
  count: number;
  containing_trees: ContainingTree[];
  is_checked: boolean;
  is_filter_by_phenotypes: boolean;
  sort_value: number;
  quick_filter: boolean;
  created_at: string;
}

export interface GeneviewFilter {
  label_id: string;
  label_name: string;
  description: string;
  created_at: number;
  sort_value: number;
  is_verified: boolean;
  is_visible: boolean;
  is_from_tree: boolean;
  from_tree_variants_count: number;
  raw_filter_value: string;
}

export interface FilterTreeLabels {
  labels: FilterTreeLabel[];
  quick_filter_labels: FilterTreeLabel[];
  gv_labels: GeneviewFilter[];
  last_search?: string;
}

export interface FilterTree {
  tree: Tree;
  nodes_extra_data?: NodesExtraData;
  filter_definitions?: FilterDefinitions;
  tree_assays?: string[];
}

export interface FilterTreeMetadata {
  tree_id: string;
  name: string;
  description?: string;
  case_type?: AnalysisType;
  variant_type?: VariantType;
  metadata?: {
    assays: string[];
    analysis_type: AnalysisType;
  };
  use_phenotypes_as_filter: boolean;
}

export interface Tree extends FilterTreeMetadata {
  start_node_ids: string[];
  nodes: Node[];
}

export interface Node {
  node_id: string;
  node_name: string;
  filter_node?: FilterNode;
  merge_node?: MergeNode;
}

export interface FilterNode {
  filter_options: FilterOption[];
}

export interface FilterLabel {
  label_name: string;
  label_description: string;
}

export interface FilterOption {
  filter_option_id: string;
  filter_option_name: string;
  matching_edges: MatchingEdge[];
  labels?: FilterLabel[];
  disabled?: boolean;
}

export interface MatchingEdge {
  node_id: string;
  connect_point: string;
}

export interface MergeNode {
  outgoing_edges?: MatchingEdge[];
  labels?: FilterLabel[];
}

export interface NodesExtraData {
  [nodeId: string]: NodeExtraData;
}

export interface NodeExtraData {
  filter_node_extra_data?: FilterNodeExtraData;
  merge_node_extra_data?: FilterNodeExtraData;
}

export interface FilterNodeExtraData {
  in_count: number;
  filter_options_extra_data?: FilterOptionsExtraData;
}

export interface FilterOptionsExtraData {
  [nodeOptionId: string]: NodeOption;
}

export interface NodeOption {
  match_count: number;
}

export interface FilterDefinitions {
  [nodeOptionId: string]: FilterDefinition;
}

export interface RangeFilterValue {
  start: number;
  end: number;
}

const round = (value: number): number => {
  return Math.round(value * 10 ** 2) / 10 ** 2;
};

export const getRangeFilterValueLabel = (value: RangeFilterValue, includeNa: boolean, isPercent: boolean): string => {
  if (value.start === null && value.end === null) {
    return 'N/A';
  }
  const suffix: string = isPercent ? '%' : '';
  const naSuffix: string = includeNa ? '; N/A' : '';
  const multiplier: number = isPercent ? 100 : 1;
  const end: string = value.end?.toString() === 'N/A' ? null : round(value.end * multiplier) + suffix;
  const start: string = value.start?.toString() === 'N/A' ? null : round(value.start * multiplier) + suffix;
  if (isNaN(value.start) && !isNaN(value.end)) {
    return '\u2264' + end + naSuffix;
  }
  if (!isNaN(value.start) && isNaN(value.end)) {
    return '\u2265' + start + naSuffix;
  }
  if (isNaN(value.start) && isNaN(value.end) && naSuffix) {
    return 'N/A';
  }
  return start + '-' + end + naSuffix;
};

export const getDefinitionLabel = (definition: FilterDefinitionOption, isPercent: boolean): string => {
  return (
    definition?.range_filter?.values.map((value) =>
      getRangeFilterValueLabel(value, definition.range_filter?.include_na, isPercent),
    ) || definition?.enum_filter?.values
  )?.join(', ');
};

export interface FilterDefinitionOption {
  filter_name: string;
  enum_filter?: {
    values: string[];
  };
  range_filter?: {
    values: RangeFilterValue[];
    include_na?: boolean;
  };
  compound_snp_snp_filter?: {
    values: string[];
  };
}

export interface FilterDefinition {
  filters: FilterDefinitionOption[];
}

export type FilterList = FilterListItem[];
export type FilterListItem = {
  xUnits: number;
  yUnits: number;
  label: string;
  count: number;
  quickFilterLabel?: string;
  isMerge?: boolean;
  isCollapsedSubTree?: boolean;
  isCompound?: boolean;
  nodeId?: string;
  values: FilterListItemValue[];
  connections: FilterListItemConnection[];
  isConnectedToCompound?: boolean;
  isConnectedToCompoundIndex?: number;
};
export type FilterListItemValue = {
  xUnits: number;
  yUnits: number;
  label: string;
  details?: string;
  count: number;
  quickFilterLabel?: string;
  optionId?: string;
  isConnectedToCompound?: boolean;
  isConnectedToCompoundIndex?: number;
  isDisabled: boolean;
  connections: FilterListItemConnection[];
};
export type FilterListItemConnection = { xUnits: number; yUnits: number; nodeId?: string; mergeNode?: boolean };

export const FILTER_TREE_CASE_TYPE_OPTIONS: SelectOption[] = [
  {
    label: 'Single',
    value: 'sample',
  },
  {
    label: 'Family',
    value: 'family',
  },
  {
    label: 'Somatic',
    value: 'tumor',
  },
];

export const FILTER_TREE_VARIANT_TYPE_OPTIONS: SelectOption[] = [
  {
    label: 'SNP',
    value: 'snp',
  },
  {
    label: 'SV',
    value: 'sv',
  },
];

export function filterTreeInfoToFilterTree(treeInfo: FilterTreeInfo): FilterTree {
  return {
    tree: {
      tree_id: treeInfo.id,
      name: treeInfo.name,
      description: treeInfo.description,
      case_type: treeInfo.analysis_type,
      variant_type: treeInfo.variant_type, // Backend does not return type yet and everything is snp ATM
      start_node_ids: [],
      nodes: [],
      use_phenotypes_as_filter: treeInfo.use_phenotypes_as_filter,
    },
    tree_assays: treeInfo.assays,
  };
}

export interface UIFilterModel {
  // this model is used for UI displaying both GV quick filters and tree labels
  id: string;
  name: string;
  description?: string;
  count: number;
  verified: boolean;
  isTree: boolean;
  isTreeLabel: boolean;
  treeId?: string;
  isMerged: boolean;
  quick_filter: boolean;
  sort_value: number;
  created_at: string;
  filter_content: { [key: string]: string[] };
  type: 'tree_label' | 'quick_filter_label' | 'geneview_default_filter' | 'geneview_user_filter';
}

export interface UpdateQuickFiltersRequest {
  assay_id?: string;
  analysis_id: string;
  tree_labels_metadata: {
    label_id: string;
    quick_filter: boolean;
    sort_value: number;
  }[];
  quick_filters_metadata: {
    quick_filter_id: number;
    quick_filter: boolean;
    sort_value: number;
  }[];
}

export const filterTreeLabelsToUIFilterModel = (labels: FilterTreeLabels): UIFilterModel[] => {
  return (
    [
      ...(labels?.quick_filter_labels || []).map((lbl) => ({
        id: lbl.label_id,
        name: lbl.label_name,
        description: lbl.description,
        verified: lbl.is_checked,
        isTree: true,
        isTreeLabel: false,
        treeId: lbl.containing_trees?.[0]?.tree_id,
        isMerged: false,
        count: lbl.count,
        quick_filter: lbl.quick_filter ?? true,
        sort_value: lbl.sort_value ?? -1,
        created_at: lbl.created_at,
        filter_content: {
          selected_label_id: [lbl.label_id],
          phen_filter: lbl.is_filter_by_phenotypes ? ['true'] : undefined,
        },
        type: lbl.label_name.toLowerCase() === 'default' ? 'geneview_default_filter' : 'quick_filter_label',
      })),
      ...(labels?.labels || []).map((lbl) => ({
        id: lbl.label_id,
        name: lbl.label_name,
        count: lbl.count,
        verified: lbl.is_checked,
        isTree: true,
        isTreeLabel: true,
        treeId: lbl.containing_trees?.[0]?.tree_id,
        isMerged: false,
        quick_filter: lbl.quick_filter ?? true,
        sort_value: lbl.sort_value ?? -1,
        created_at: lbl.created_at,
        filter_content: {
          selected_label_id: [lbl.label_id],
          phen_filter: lbl.is_filter_by_phenotypes ? ['true'] : undefined,
          selected_filter_tree_id: [lbl.containing_trees?.[0]?.tree_id]
        },
        type: 'tree_label',
      })),
      ...(labels?.gv_labels || []).map((lbl) => ({
        id: lbl.label_id,
        name: lbl.label_name,
        description: lbl.description,
        verified: lbl.is_verified,
        isTree: lbl.is_from_tree,
        count: lbl.from_tree_variants_count,
        treeId: lbl.is_from_tree ? lbl.label_id : undefined,
        isTreeLabel: false,
        isMerged: JSON.parse(lbl.raw_filter_value)?.quick_filter?.length,
        quick_filter: lbl.is_visible ?? true,
        sort_value: lbl.sort_value ?? -1,
        created_at: lbl.created_at,
        filter_content: JSON.parse(lbl.raw_filter_value),
        type: lbl.label_name.toLowerCase() === 'default' ? 'geneview_default_filter' : 'geneview_user_filter',
      })),
    ] as UIFilterModel[]
  ).sort((a, b) =>
    a.sort_value === b.sort_value
      ? new Date(a.created_at) <= new Date(b.created_at)
        ? 1
        : -1
      : a.sort_value - b.sort_value,
  );
};
